<template>
  <div class="demo-inline-spacing">
    <b-spinner
      class="mr-1 m-auto"
      variant="primary"
    />
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
  },
}
</script>