<template>
    <body bgcolor="#ffffff" text="#3b3f44" link="#0092ff" yahoo="fix" style="background-color: #ffffff;">
    <table cellspacing="0" cellpadding="0" border="0" role="presentation" class="nl2go-body-table" width="100%" style="background-color: #ffffff; width: 100%;">
        <tr>
            <td align="center" class="r0-c">
                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" class="r1-o" style="table-layout: fixed; width: 600px;">
                    <tr>
                        <td valign="top" class="r2-i" style="background-color: #ffffff;">
                            <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                <tr>
                                    <td class="r3-c" align="center">
                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r4-o" style="background-color: #ffffff; background-image: url('https://img.mailinblue.com/4759347/images/content_library/original/62bc6c8ebe1f0308ce43a533.jpg'); background-position: top; background-repeat: no-repeat; background-size: auto; font-size: 0; table-layout: fixed; width: 100%;">
                                            <!-- -->
                                            <tr class="nl2go-responsive-hide">
                                                <td height="12" style="font-size: 12px; line-height: 12px;">­</td>
                                            </tr>
                                            <tr>
                                                <td class="r5-i">
                                                    <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                        <tr>
                                                            <th width="100%" valign="top" class="r6-c" style="font-weight: normal;">
                                                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                    <!-- -->
                                                                    <tr>
                                                                        <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                        <td valign="top" class="r8-i">
                                                                            <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                <tr>
                                                                                    <td class="r9-c" align="center">
                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="130" class="r10-o" style="table-layout: fixed; width: 130px;">
                                                                                            <tr>
                                                                                                <td class="r11-i" style="font-size: 0px; line-height: 0px;" v-if="user.empresa">
                                                                                                 <img  :src="(user.empresa.logo)?user.empresa.logo :'https://img.mailinblue.com/4759347/images/content_library/original/62bb032894f9533066676e5c.png'" width="130" border="0" class="" style="display: block; width: 100%"></td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </td>
                                                                        <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                    </tr>
                                                                </table>
                                                            </th>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr class="nl2go-responsive-hide">
                                                <td height="49" style="font-size: 49px; line-height: 49px;">­</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="r3-c" align="center">
                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r12-o" style="background-color: #ffffff; background-image: url('https://img.mailinblue.com/4759347/images/content_library/original/62bc7605e9634d556b5033f0.jpg'); background-position: top; background-repeat: repeat-y; background-size: auto; font-size: 0; table-layout: fixed; width: 100%;">
                                            <!-- -->
                                            <tr>
                                                <td class="nl2go-responsive-hide" width="25" style="font-size: 0px; line-height: 1px;">­ </td>
                                                <td class="r13-i">
                                                    <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                        <tr>
                                                            <th width="100%" valign="top" class="r6-c" style="font-weight: normal;">
                                                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                    <!-- -->
                                                                    <tr>
                                                                        <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                        <td valign="top" class="r8-i">
                                                                            <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                <tr>
                                                                                    <td class="r3-c" align="center">
                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="400" class="r14-o" style="table-layout: fixed; width: 400px;">
                                                                                            <tr class="nl2go-responsive-hide">
                                                                                                <td height="25" style="font-size: 25px; line-height: 25px;">­</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td class="r15-i" style="font-size: 0px; line-height: 0px;"> <img src="https://img.mailinblue.com/4759347/images/content_library/original/62bc7373874f41635d67b1c4.png" width="400" border="0" class="" style="display: block; width: 100%;"></td>
                                                                                            </tr>
                                                                                            <tr class="nl2go-responsive-hide">
                                                                                                <td height="40" style="font-size: 40px; line-height: 40px;">­</td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="r3-c" align="center">
                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="450" class="r16-o" style="table-layout: fixed; width: 450px;">
                                                                                            <tr>
                                                                                                <td align="center" valign="top" class="r17-i nl2go-default-textstyle" style="color: #3b3f44; font-family: arial,helvetica,sans-serif; font-size: 16px; line-height: 2; ">
                                                                                                    <div v-html="message">
                                                                                                        <!-- <p style="margin: 0;">
                                                                                                            <span style="font-family: Verdana, geneva, sans-serif;" >
                                                                                                            </span>
                                                                                                        </p> -->
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr class="nl2go-responsive-hide">
                                                                                                <td height="40" style="font-size: 40px; line-height: 40px;">­</td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="r18-c" align="center">
                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="180" class="r19-o" style="table-layout: fixed; width: 180px;">
                                                                                            <tr>
                                                                                                <td height="18" align="center" valign="top" class="r20-i nl2go-default-textstyle" style="color: #3b3f44; font-family: arial,helvetica,sans-serif; font-size: 16px; line-height: 1.5;">
                                                                                                    <a class="r21-r default-button" target="_blank" data-btn="1" style="font-style: normal; font-weight: normal; line-height: 1.15; text-decoration: none; border-style: solid; display: inline-block; -webkit-text-size-adjust: none; mso-hide: all; background-color: #ff4b60; border-color: #ff4b60; border-radius: 4px; border-width: 0px; color: #ffffff; font-family: arial,helvetica,sans-serif; font-size: 16px; padding-bottom: 12px; padding-left: 5px; padding-right: 5px; padding-top: 12px; width: 170px;">
                                                                                                        <p style="margin: 0;"><strong>VER MÁS</strong></p>
                                                                                                    </a>
                                                                                                    <!--<![endif]-->
                                                                                                </td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </td>
                                                                        <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                    </tr>
                                                                </table>
                                                            </th>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td class="nl2go-responsive-hide" width="25" style="font-size: 0px; line-height: 1px;">­ </td>
                                            </tr>
                                            <tr class="nl2go-responsive-hide">
                                                <td height="15" width="25" style="font-size: 15px; line-height: 15px;">­ </td>
                                                <td height="15" style="font-size: 15px; line-height: 15px;">­</td>
                                                <td height="15" width="25" style="font-size: 15px; line-height: 15px;">­ </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="r3-c" align="center">
                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r22-o" style="background-color: #ffdce0; background-image: url('https://img.mailinblue.com/4759347/images/content_library/original/62bc7af9bb2165344f7595c3.jpg'); background-position: top; background-repeat: repeat-y; background-size: auto; font-size: 0; table-layout: fixed; width: 100%;">
                                            <!-- -->
                                            <tr class="nl2go-responsive-hide">
                                                <td height="40" width="25" style="font-size: 40px; line-height: 40px;">­ </td>
                                                <td height="40" style="font-size: 40px; line-height: 40px;">­</td>
                                                <td height="40" width="25" style="font-size: 40px; line-height: 40px;">­ </td>
                                            </tr>
                                            <tr>
                                                <td class="nl2go-responsive-hide" width="25" style="font-size: 0px; line-height: 1px;">­ </td>
                                                <td class="r23-i">
                                                    <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                        <tr>
                                                            <th width="100%" valign="top" class="r6-c" style="font-weight: normal;">
                                                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                    <!-- -->
                                                                    <tr>
                                                                        <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                        <td valign="top" class="r8-i">
                                                                            <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                <tr>
                                                                                    <td class="r24-c" align="left">
                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r25-o" style="table-layout: fixed; width: 100%;">
                                                                                            <tr>
                                                                                                <td align="center" valign="top" class="r26-i nl2go-default-textstyle" style="color: #3b3f44; font-family: arial,helvetica,sans-serif; font-size: 16px; line-height: 1.15; text-align: center;">
                                                                                                    <div>
                                                                                                        <p style="margin: 0;"><span style="font-family: 'Trebuchet ms', helvetica, sans-serif; font-size: 14px;"><strong>REDES </strong></span><span style="font-family: 'Trebuchet ms', helvetica, sans-serif;"><strong>SOCIALES</strong></span></p>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="r27-c">
                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="520" class="r7-o" style="table-layout: fixed; width: 520px;">
                                                                                            <!-- -->
                                                                                            <tr>
                                                                                                <td valign="top" class="">
                                                                                                    <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                        <tr>
                                                                                                            <td class="r28-c" align="center" style="display: inline-block;">
                                                                                                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="520" class="r29-o" style="table-layout: fixed; width: 520px;">
                                                                                                                    <!-- -->
                                                                                                                    <tr class="nl2go-responsive-hide">
                                                                                                                        <td height="15" width="204" style="font-size: 15px; line-height: 15px;">­ </td>
                                                                                                                        <td height="15" style="font-size: 15px; line-height: 15px;">­</td>
                                                                                                                        <td height="15" width="204" style="font-size: 15px; line-height: 15px;">­ </td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td class="nl2go-responsive-hide" width="204" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                                                                        <td class="r30-i">
                                                                                                                            <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                                <tr>
                                                                                                                                    <th width="40" valign="" class="r31-c mobshow resp-table" style="font-weight: normal;">
                                                                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r32-o" style="table-layout: fixed; width: 100%;">
                                                                                                                                            <!-- -->
                                                                                                                                            <tr class="nl2go-responsive-hide">
                                                                                                                                                <td height="5" style="font-size: 5px; line-height: 5px;">­</td>
                                                                                                                                                <td height="5" width="8" style="font-size: 5px; line-height: 5px;">­ </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td class="r33-i" style="font-size: 0px; line-height: 0px;"> <img src="https://sendinblue-templates.s3.eu-west-3.amazonaws.com/icons/rounded_colored/facebook_32px.png" width="32" border="0" class="" style="display: block; width: 100%;"></td>
                                                                                                                                                <td class="nl2go-responsive-hide" width="8" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr class="nl2go-responsive-hide">
                                                                                                                                                <td height="5" style="font-size: 5px; line-height: 5px;">­</td>
                                                                                                                                                <td height="5" width="8" style="font-size: 5px; line-height: 5px;">­ </td>
                                                                                                                                            </tr>
                                                                                                                                        </table>
                                                                                                                                    </th>
                                                                                                                                    <th width="40" valign="" class="r31-c mobshow resp-table" style="font-weight: normal;">
                                                                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r32-o" style="table-layout: fixed; width: 100%;">
                                                                                                                                            <!-- -->
                                                                                                                                            <tr class="nl2go-responsive-hide">
                                                                                                                                                <td height="5" style="font-size: 5px; line-height: 5px;">­</td>
                                                                                                                                                <td height="5" width="8" style="font-size: 5px; line-height: 5px;">­ </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td class="r33-i" style="font-size: 0px; line-height: 0px;"> <img src="https://sendinblue-templates.s3.eu-west-3.amazonaws.com/icons/rounded_colored/linkedin_32px.png" width="32" border="0" class="" style="display: block; width: 100%;"></td>
                                                                                                                                                <td class="nl2go-responsive-hide" width="8" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr class="nl2go-responsive-hide">
                                                                                                                                                <td height="5" style="font-size: 5px; line-height: 5px;">­</td>
                                                                                                                                                <td height="5" width="8" style="font-size: 5px; line-height: 5px;">­ </td>
                                                                                                                                            </tr>
                                                                                                                                        </table>
                                                                                                                                    </th>
                                                                                                                                    <th width="32" valign="" class="r31-c mobshow resp-table" style="font-weight: normal;">
                                                                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r32-o" style="table-layout: fixed; width: 100%;">
                                                                                                                                            <!-- -->
                                                                                                                                            <tr class="nl2go-responsive-hide">
                                                                                                                                                <td height="5" style="font-size: 5px; line-height: 5px;">­</td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td class="r33-i" style="font-size: 0px; line-height: 0px;"> <img src="https://sendinblue-templates.s3.eu-west-3.amazonaws.com/icons/rounded_colored/instagram_32px.png" width="32" border="0" class="" style="display: block; width: 100%;"></td>
                                                                                                                                            </tr>
                                                                                                                                            <tr class="nl2go-responsive-hide">
                                                                                                                                                <td height="5" style="font-size: 5px; line-height: 5px;">­</td>
                                                                                                                                            </tr>
                                                                                                                                        </table>
                                                                                                                                    </th>
                                                                                                                                </tr>
                                                                                                                            </table>
                                                                                                                        </td>
                                                                                                                        <td class="nl2go-responsive-hide" width="204" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                                                                    </tr>
                                                                                                                    <tr class="nl2go-responsive-hide">
                                                                                                                        <td height="15" width="204" style="font-size: 15px; line-height: 15px;">­ </td>
                                                                                                                        <td height="15" style="font-size: 15px; line-height: 15px;">­</td>
                                                                                                                        <td height="15" width="204" style="font-size: 15px; line-height: 15px;">­ </td>
                                                                                                                    </tr>
                                                                                                                </table>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </table>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="r3-c" align="center">
                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="400" class="r34-o" style="table-layout: fixed; width: 400px;">
                                                                                            <tr>
                                                                                                <td align="center" valign="top" class="r35-i nl2go-default-textstyle" style="font-family: arial,helvetica,sans-serif; color: #3b3f44; font-size: 18px; line-height: 1.5; text-align: center;">
                                                                                                    <div>
                                                                                                        <p style="margin: 0; font-size: 14px;"><span style="font-family: 'Trebuchet ms', helvetica, sans-serif;"><strong>HOMIUP © 2022 | CRM RED</strong></span></p>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr class="nl2go-responsive-hide">
                                                                                                <td height="20" style="font-size: 20px; line-height: 20px;">­</td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </td>
                                                                        <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                    </tr>
                                                                </table>
                                                            </th>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td class="nl2go-responsive-hide" width="25" style="font-size: 0px; line-height: 1px;">­ </td>
                                            </tr>
                                            <tr class="nl2go-responsive-hide">
                                                <td height="30" width="25" style="font-size: 30px; line-height: 30px;">­ </td>
                                                <td height="30" style="font-size: 30px; line-height: 30px;">­</td>
                                                <td height="30" width="25" style="font-size: 30px; line-height: 30px;">­ </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</body>
</template>

<script>
export default{
    props:{
        user:{
            type:Object,
            default:null
        },
        message:{
            type:String
        }
    }
} 
</script>

<style scoped type="text/css">
a:link {
    color: #0092ff;
    text-decoration: underline
}
</style>