<template>
    <body bgcolor="#ffffff" text="#3b3f44" link="#0092ff" yahoo="fix" style="background-color: #ffffff; padding-bottom: 0px; padding-top: 0px;">
    <table cellspacing="0" cellpadding="0" border="0" role="presentation" class="nl2go-body-table" width="100%" style="background-color: #ffffff; width: 100%;">
        <tr>
            <td align="center" class="r0-c">
                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" class="r1-o" style="table-layout: fixed; width: 600px;">
                    <tr>
                        <td valign="top" class="r2-i" style="background-color: #ffffff;">
                            <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                <tr>
                                    <td class="r3-c" align="center">
                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r4-o" style="background-color: #dbecf7; background-image: url('https://img.mailinblue.com/4759347/images/content_library/original/62bcb0c51ec21d7f1566a5fe.png'); background-position: top; background-repeat: no-repeat; background-size: auto; font-size: 0; table-layout: fixed; width: 100%;">
                                            <!-- -->
                                            <tr class="nl2go-responsive-hide">
                                                <td height="35" style="font-size: 35px; line-height: 35px;">­</td>
                                            </tr>
                                            <tr>
                                                <td class="r5-i">
                                                    <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                        <tr>
                                                            <th width="100%" valign="top" class="r6-c" style="font-weight: normal;">
                                                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                    <!-- -->
                                                                    <tr>
                                                                        <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                        <td valign="top" class="r8-i">
                                                                            <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                <tr>
                                                                                    <td class="r9-c" align="center">
                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="180" class="r10-o" style="table-layout: fixed; width: 180px;">
                                                                                            <tr>
                                                                                                <td class="r11-i" style="font-size: 0px; line-height: 0px;" v-if="user.empresa"> 
                                                                                                <img :src="(user.empresa.logo)?user.empresa.logo :'https://img.mailinblue.com/4759347/images/content_library/original/62bb032894f9533066676e5c.png'" width="180" border="0" class="" style="display: block; width: 100%;"></td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </td>
                                                                        <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                    </tr>
                                                                </table>
                                                            </th>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr class="nl2go-responsive-hide">
                                                <td height="20" style="font-size: 20px; line-height: 20px;">­</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="r3-c" align="center">
                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r12-o" style="background-color: #dbecf7; background-image: url('https://img.mailinblue.com/4759347/images/content_library/original/62bcb0c58c744308d257adae.png'); background-position: center; background-repeat: repeat-y; background-size: auto; font-size: 0; table-layout: fixed; width: 100%;">
                                            <!-- -->
                                            <tr>
                                                <td class="nl2go-responsive-hide" width="25" style="font-size: 0px; line-height: 1px;">­ </td>
                                                <td class="r13-i">
                                                    <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                        <tr>
                                                            <th width="100%" valign="top" class="r6-c" style="font-weight: normal;">
                                                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                    <!-- -->
                                                                    <tr>
                                                                        <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                        <td valign="top" class="r8-i">
                                                                            <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                <tr>
                                                                                    <td class="r3-c" align="center">
                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="420" class="r14-o" style="table-layout: fixed; width: 420px;">
                                                                                            <tr>
                                                                                                <td class="r15-i" style="font-size: 0px; line-height: 0px;"> <img src="https://img.mailinblue.com/4759347/images/content_library/original/62bcba4de3465546ed220226.png" width="420" border="0" class="" style="display: block; width: 100%;"></td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="r3-c" align="center">
                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="450" class="r16-o" style="table-layout: fixed; width: 450px;">
                                                                                            <tr class="nl2go-responsive-hide">
                                                                                                <td height="30" style="font-size: 30px; line-height: 30px;">­</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td align="center" valign="top" class="r17-i nl2go-default-textstyle" style="color: #3b3f44; font-family: arial,helvetica,sans-serif; font-size: 16px; line-height: 1.8; text-align: center;">
                                                                                                    <div>
                                                                                                        <p style="margin: 0;">
                                                                                                            <span style="font-family: 'Lucida sans unicode', 'lucida grande', sans-serif;" v-html="message">
                                                                                                            </span>
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr class="nl2go-responsive-hide">
                                                                                                <td height="10" style="font-size: 10px; line-height: 10px;">­</td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </td>
                                                                        <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                    </tr>
                                                                </table>
                                                            </th>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td class="nl2go-responsive-hide" width="25" style="font-size: 0px; line-height: 1px;">­ </td>
                                            </tr>
                                            <tr class="nl2go-responsive-hide">
                                                <td height="15" width="25" style="font-size: 15px; line-height: 15px;">­ </td>
                                                <td height="15" style="font-size: 15px; line-height: 15px;">­</td>
                                                <td height="15" width="25" style="font-size: 15px; line-height: 15px;">­ </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="r3-c" align="center">
                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r18-o" style="background-color: #dbecf7; background-image: url('https://img.mailinblue.com/4759347/images/content_library/original/62bcb0c554ef3a4cb800b70b.png'); background-position: bottom; background-repeat: no-repeat; background-size: auto; font-size: 0; table-layout: fixed; width: 100%;">
                                            <!-- -->
                                            <tr class="nl2go-responsive-hide">
                                                <td height="40" style="font-size: 40px; line-height: 40px;">­</td>
                                            </tr>
                                            <tr>
                                                <td class="r19-i">
                                                    <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                        <tr>
                                                            <th width="100%" valign="top" class="r6-c" style="font-weight: normal;">
                                                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
                                                                    <!-- -->
                                                                    <tr>
                                                                        <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                        <td valign="top" class="r8-i">
                                                                            <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                <tr>
                                                                                    <td class="r20-c" align="left">
                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r21-o" style="table-layout: fixed; width: 100%;">
                                                                                            <tr>
                                                                                                <td align="center" valign="top" class="r22-i nl2go-default-textstyle" style="color: #3b3f44; font-family: arial,helvetica,sans-serif; font-size: 16px; line-height: 1.15; text-align: center;">
                                                                                                    <div>
                                                                                                        <p style="margin: 0;"><span style="font-family: 'Trebuchet ms', helvetica, sans-serif; font-size: 14px;"><strong>REDES </strong></span><span style="font-family: 'Trebuchet ms', helvetica, sans-serif;"><strong>SOCIALES</strong></span></p>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="r23-c">
                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="570" class="r7-o" style="table-layout: fixed; width: 570px;">
                                                                                            <!-- -->
                                                                                            <tr>
                                                                                                <td valign="top" class="">
                                                                                                    <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                        <tr>
                                                                                                            <td class="r24-c" align="center" style="display: inline-block;">
                                                                                                                <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="570" class="r25-o" style="table-layout: fixed; width: 570px;">
                                                                                                                    <!-- -->
                                                                                                                    <tr class="nl2go-responsive-hide">
                                                                                                                        <td height="15" width="229" style="font-size: 15px; line-height: 15px;">­ </td>
                                                                                                                        <td height="15" style="font-size: 15px; line-height: 15px;">­</td>
                                                                                                                        <td height="15" width="229" style="font-size: 15px; line-height: 15px;">­ </td>
                                                                                                                    </tr>
                                                                                                                    <tr>
                                                                                                                        <td class="nl2go-responsive-hide" width="229" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                                                                        <td class="r26-i">
                                                                                                                            <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                                                                                                                                <tr>
                                                                                                                                    <th width="40" valign="" class="r27-c mobshow resp-table" style="font-weight: normal;">
                                                                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r28-o" style="table-layout: fixed; width: 100%;">
                                                                                                                                            <!-- -->
                                                                                                                                            <tr class="nl2go-responsive-hide">
                                                                                                                                                <td height="5" style="font-size: 5px; line-height: 5px;">­</td>
                                                                                                                                                <td height="5" width="8" style="font-size: 5px; line-height: 5px;">­ </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td class="r29-i" style="font-size: 0px; line-height: 0px;"> <img src="https://sendinblue-templates.s3.eu-west-3.amazonaws.com/icons/squared_colored/facebook_32px.png" width="32" border="0" class="" style="display: block; width: 100%;"></td>
                                                                                                                                                <td class="nl2go-responsive-hide" width="8" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr class="nl2go-responsive-hide">
                                                                                                                                                <td height="5" style="font-size: 5px; line-height: 5px;">­</td>
                                                                                                                                                <td height="5" width="8" style="font-size: 5px; line-height: 5px;">­ </td>
                                                                                                                                            </tr>
                                                                                                                                        </table>
                                                                                                                                    </th>
                                                                                                                                    <th width="40" valign="" class="r27-c mobshow resp-table" style="font-weight: normal;">
                                                                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r28-o" style="table-layout: fixed; width: 100%;">
                                                                                                                                            <!-- -->
                                                                                                                                            <tr class="nl2go-responsive-hide">
                                                                                                                                                <td height="5" style="font-size: 5px; line-height: 5px;">­</td>
                                                                                                                                                <td height="5" width="8" style="font-size: 5px; line-height: 5px;">­ </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td class="r29-i" style="font-size: 0px; line-height: 0px;"> <img src="https://sendinblue-templates.s3.eu-west-3.amazonaws.com/icons/squared_colored/linkedin_32px.png" width="32" border="0" class="" style="display: block; width: 100%;"></td>
                                                                                                                                                <td class="nl2go-responsive-hide" width="8" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr class="nl2go-responsive-hide">
                                                                                                                                                <td height="5" style="font-size: 5px; line-height: 5px;">­</td>
                                                                                                                                                <td height="5" width="8" style="font-size: 5px; line-height: 5px;">­ </td>
                                                                                                                                            </tr>
                                                                                                                                        </table>
                                                                                                                                    </th>
                                                                                                                                    <th width="32" valign="" class="r27-c mobshow resp-table" style="font-weight: normal;">
                                                                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r28-o" style="table-layout: fixed; width: 100%;">
                                                                                                                                            <!-- -->
                                                                                                                                            <tr class="nl2go-responsive-hide">
                                                                                                                                                <td height="5" style="font-size: 5px; line-height: 5px;">­</td>
                                                                                                                                            </tr>
                                                                                                                                            <tr>
                                                                                                                                                <td class="r29-i" style="font-size: 0px; line-height: 0px;"> <img src="https://sendinblue-templates.s3.eu-west-3.amazonaws.com/icons/squared_colored/instagram_32px.png" width="32" border="0" class="" style="display: block; width: 100%;"></td>
                                                                                                                                            </tr>
                                                                                                                                            <tr class="nl2go-responsive-hide">
                                                                                                                                                <td height="5" style="font-size: 5px; line-height: 5px;">­</td>
                                                                                                                                            </tr>
                                                                                                                                        </table>
                                                                                                                                    </th>
                                                                                                                                </tr>
                                                                                                                            </table>
                                                                                                                        </td>
                                                                                                                        <td class="nl2go-responsive-hide" width="229" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                                                                    </tr>
                                                                                                                    <tr class="nl2go-responsive-hide">
                                                                                                                        <td height="15" width="229" style="font-size: 15px; line-height: 15px;">­ </td>
                                                                                                                        <td height="15" style="font-size: 15px; line-height: 15px;">­</td>
                                                                                                                        <td height="15" width="229" style="font-size: 15px; line-height: 15px;">­ </td>
                                                                                                                    </tr>
                                                                                                                </table>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </table>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="r3-c" align="center">
                                                                                        <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="400" class="r30-o" style="table-layout: fixed; width: 400px;">
                                                                                            <tr>
                                                                                                <td align="center" valign="top" class="r31-i nl2go-default-textstyle" style="font-family: arial,helvetica,sans-serif; color: #3b3f44; font-size: 18px; line-height: 1.5; text-align: center;">
                                                                                                    <div>
                                                                                                        <p style="margin: 0; font-size: 14px;"><span style="font-family: 'Trebuchet ms', helvetica, sans-serif;"><strong>CRMRED © 2022 | CRM RED</strong></span></p>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr class="nl2go-responsive-hide">
                                                                                                <td height="20" style="font-size: 20px; line-height: 20px;">­</td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </td>
                                                                        <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">­ </td>
                                                                    </tr>
                                                                </table>
                                                            </th>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr class="nl2go-responsive-hide">
                                                <td height="60" style="font-size: 60px; line-height: 60px;">­</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</body>
</template>

<script>
export default{
    props:{
        message:{
            type:String
        },
        user:{
            type:Object,
            default:null
        }
    }
}
</script>

<style type="text/css" emogrify="no" scoped>
#outlook a {
    padding: 0;
}

.ExternalClass {
    width: 100%;
}

.ExternalClass,
.ExternalClass p,
.ExternalClass span,
.ExternalClass font,
.ExternalClass td,
.ExternalClass div {
    line-height: 100%;
}

table td {
    border-collapse: collapse;
    mso-line-height-rule: exactly;
}

.editable.image {
    font-size: 0 !important;
    line-height: 0 !important;
}

.nl2go_preheader {
    display: none !important;
    mso-hide: all !important;
    mso-line-height-rule: exactly;
    visibility: hidden !important;
    line-height: 0px !important;
    font-size: 0px !important;
}

body {
    width: 100% !important;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    margin: 0;
    padding: 0;
}

img {
    outline: none;
    text-decoration: none;
    -ms-interpolation-mode: bicubic;
}

a img {
    border: none;
}

table {
    border-collapse: collapse;
    mso-table-lspace: 0pt;
    mso-table-rspace: 0pt;
}

th {
    font-weight: normal;
    text-align: left;
}

*[class="gmail-fix"] {
    display: none !important;
}
</style>
<style type="text/css" emogrify="no" scoped>
@media (max-width: 600px) {
    .gmx-killpill {
        content: ' \03D1';
    }
}
</style>
<style type="text/css" emogrify="no" scoped>
@media (max-width: 600px) {
    .gmx-killpill {
        content: ' \03D1';
    }

    .r0-c {
        box-sizing: border-box !important;
        text-align: center !important;
        valign: top !important;
        width: 320px !important
    }

    .r1-o {
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        width: 320px !important
    }

    .r2-i {
        background-color: #ffffff !important
    }

    .r3-c {
        box-sizing: border-box !important;
        text-align: center !important;
        valign: top !important;
        width: 100% !important
    }

    .r4-o {
        background-image: url('https://img.mailinblue.com/4759347/images/content_library/original/62bcb0c51ec21d7f1566a5fe.png') !important;
        background-size: auto !important;
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        width: 100% !important
    }

    .r5-i {
        padding-bottom: 20px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        padding-top: 35px !important
    }

    .r6-c {
        box-sizing: border-box !important;
        display: block !important;
        valign: top !important;
        width: 100% !important
    }

    .r7-o {
        border-style: solid !important;
        width: 100% !important
    }

    .r8-i {
        padding-left: 0px !important;
        padding-right: 0px !important
    }

    .r9-c {
        box-sizing: border-box !important;
        text-align: center !important;
        valign: top !important;
        width: 180px !important
    }

    .r10-o {
        background-size: auto !important;
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        width: 180px !important
    }

    .r11-i {
        padding-bottom: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 5px !important
    }

    .r12-o {
        background-image: url('https://img.mailinblue.com/4759347/images/content_library/original/62bcb0c58c744308d257adae.png') !important;
        background-size: auto !important;
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
        width: 100% !important
    }

    .r13-i {
        padding-bottom: 15px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        padding-top: 0px !important
    }

    .r14-o {
        background-size: auto !important;
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        width: 100% !important
    }

    .r15-i {
        padding-bottom: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 0px !important
    }

    .r16-o {
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        margin-bottom: 10px !important;
        margin-top: 30px !important;
        width: 100% !important
    }

    .r17-i {
        padding-bottom: 0px !important;
        padding-top: 0px !important;
        text-align: center !important
    }

    .r18-o {
        background-image: url('https://img.mailinblue.com/4759347/images/content_library/original/62bcb0c554ef3a4cb800b70b.png') !important;
        background-size: auto !important;
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        width: 100% !important
    }

    .r19-i {
        padding-bottom: 60px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        padding-top: 40px !important
    }

    .r20-c {
        box-sizing: border-box !important;
        text-align: left !important;
        valign: top !important;
        width: 100% !important
    }

    .r21-o {
        border-style: solid !important;
        margin: 0 auto 0 0 !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
        width: 100% !important
    }

    .r22-i {
        padding-bottom: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 0px !important;
        text-align: center !important
    }

    .r23-c {
        box-sizing: border-box !important;
        width: 100% !important
    }

    .r24-c {
        box-sizing: border-box !important;
        text-align: center !important;
        width: 100% !important
    }

    .r25-o {
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        width: 100% !important
    }

    .r26-i {
        font-size: 0px !important;
        padding-bottom: 15px !important;
        padding-left: 85px !important;
        padding-right: 85px !important;
        padding-top: 15px !important
    }

    .r27-c {
        box-sizing: border-box !important;
        width: 32px !important
    }

    .r28-o {
        border-style: solid !important;
        margin-right: 8px !important;
        width: 32px !important
    }

    .r29-i {
        padding-bottom: 5px !important;
        padding-top: 5px !important
    }

    .r30-o {
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
        width: 100% !important
    }

    .r31-i {
        color: #3b3f44 !important;
        padding-bottom: 20px !important;
        padding-top: 0px !important;
        text-align: center !important
    }

    body {
        -webkit-text-size-adjust: none
    }

    .nl2go-responsive-hide {
        display: none
    }

    .nl2go-body-table {
        min-width: unset !important
    }

    .mobshow {
        height: auto !important;
        overflow: visible !important;
        max-height: unset !important;
        visibility: visible !important;
        border: none !important
    }

    .resp-table {
        display: inline-table !important
    }

    .magic-resp {
        display: table-cell !important
    }
}
</style>
<!--[if !mso]><!-->
<style type="text/css" emogrify="no" scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat");
</style>
<!--<![endif]-->
<style type="text/css" scoped>
p,
h1,
h2,
h3,
h4,
ol,
ul {
    margin: 0;
}

a,
a:link {
    color: #0092ff;
    text-decoration: underline
}

.nl2go-default-textstyle {
    color: #3b3f44;
    font-family: arial, helvetica, sans-serif;
    font-size: 16px;
    line-height: 1.5
}

.default-button {
    border-radius: 4px;
    color: #ffffff;
    font-family: arial, helvetica, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.15;
    text-decoration: none;
    width: 50%
}

.default-heading1 {
    color: #1F2D3D;
    font-family: arial, helvetica, sans-serif;
    font-size: 36px
}

.default-heading2 {
    color: #1F2D3D;
    font-family: arial, helvetica, sans-serif;
    font-size: 32px
}

.default-heading3 {
    color: #1F2D3D;
    font-family: arial, helvetica, sans-serif;
    font-size: 24px
}

.default-heading4 {
    color: #1F2D3D;
    font-family: arial, helvetica, sans-serif;
    font-size: 18px
}

a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: inherit !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
}

.no-show-for-you {
    border: none;
    display: none;
    float: none;
    font-size: 0;
    height: 0;
    line-height: 0;
    max-height: 0;
    mso-hide: all;
    overflow: hidden;
    table-layout: fixed;
    visibility: hidden;
    width: 0;
}
</style>