<template>
    <body bgcolor="#f6f6f6" text="#3b3f44" link="#0092ff" yahoo="fix" style="background-color: #f6f6f6;">

<table cellspacing="0" cellpadding="0" border="0" role="presentation" class="nl2go-body-table" width="100%" style="background-color: #f6f6f6; width: 100%;">

    <tr>

        <td align="center" class="r0-c">

            <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" class="r1-o" style="table-layout: fixed; width: 600px;">

                <tr>

                    <td valign="top" class="r2-i" style="background-color: #f6f6f6;">

                        <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">

                            <tr>

                                <td class="r3-c" align="center">

                                    <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r4-o" style="background-color: #f3f1e9; background-image: url('https://img.mailinblue.com/4759347/images/content_library/original/62bb02992487e54c3e7c7e36.png'); background-position: top; background-repeat: no-repeat; background-size: contain; font-size: 0; table-layout: fixed; width: 100%;">

                                        <!-- -->

                                        <tr class="nl2go-responsive-hide">

                                            <td height="20" style="font-size: 20px; line-height: 20px;">
                                                ­
                                            </td>

                                        </tr>

                                        <tr>

                                            <td class="r5-i">

                                                <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">

                                                    <tr>

                                                        <th width="100%" valign="top" class="r6-c" style="font-weight: normal;">

                                                            <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">

                                                                <!-- -->

                                                                <tr>

                                                                    <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">
                                                                        ­
                                                                    </td>

                                                                    <td valign="top" class="r8-i">

                                                                        <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">

                                                                            <tr>

                                                                                <td class="r9-c" align="center">

                                                                                    <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="150" class="r10-o" style="table-layout: fixed; width: 150px;">

                                                                                        <tr class="nl2go-responsive-hide">

                                                                                            <td height="220" style="font-size: 220px; line-height: 220px;">
                                                                                                ­
                                                                                            </td>

                                                                                        </tr>

                                                                                        <tr>

                                                                                            <td class="r11-i" style="font-size: 0px; line-height: 0px;" v-if="user.empresa">

                                                                                                <img  :src="(user.empresa.logo)?user.empresa.logo :'https://img.mailinblue.com/4759347/images/content_library/original/62bb032894f9533066676e5c.png'" width="150" border="0" class="" style="display: block; width: 100%;">

                                                                                            </td>

                                                                                        </tr>

                                                                                    </table>

                                                                                </td>

                                                                            </tr>

                                                                            <tr>

                                                                                <td class="r3-c" align="center">

                                                                                    <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="350" class="r12-o" style="table-layout: fixed; width: 350px;">

                                                                                        <tr class="nl2go-responsive-hide">

                                                                                            <td height="20" style="font-size: 20px; line-height: 20px;">
                                                                                                ­
                                                                                            </td>

                                                                                        </tr>

                                                                                        <tr>

                                                                                            <td class="r13-i" style="font-size: 0px; line-height: 0px;">
                                                                                            </td>

                                                                                        </tr>

                                                                                    </table>

                                                                                </td>

                                                                            </tr>

                                                                            <tr>

                                                                                <td class="r14-c" align="left">

                                                                                    <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r15-o" style="table-layout: fixed; width: 100%;">

                                                                                        <tr class="nl2go-responsive-hide">

                                                                                            <td height="10" style="font-size: 10px; line-height: 10px;">
                                                                                                ­
                                                                                            </td>

                                                                                        </tr>

                                                                                        <tr>

                                                                                            <td align="center" valign="top" class="r16-i nl2go-default-textstyle" style="color: #3b3f44; font-family: arial,helvetica,sans-serif; font-size: 16px; line-height: 1.15; text-align: center;">

                                                                                                <div>

                                                                                                    <p style="margin: 0;">

                                                                                                        <span style="font-family: 'Trebuchet ms', helvetica, sans-serif;">
                                                                                                            FELIZ
                                                                                                            <strong>
                                                                                                                AÑO NUEVO
                                                                                                            </strong>
                                                                                                            2023
                                                                                                        </span>

                                                                                                    </p>

                                                                                                </div>

                                                                                            </td>

                                                                                        </tr>

                                                                                    </table>

                                                                                </td>

                                                                            </tr>

                                                                        </table>

                                                                    </td>

                                                                    <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">
                                                                        ­
                                                                    </td>

                                                                </tr>

                                                            </table>

                                                        </th>

                                                    </tr>

                                                </table>

                                            </td>

                                        </tr>

                                        <tr class="nl2go-responsive-hide">

                                            <td height="30" style="font-size: 30px; line-height: 30px;">
                                                ­
                                            </td>

                                        </tr>

                                    </table>

                                </td>

                            </tr>

                            <tr>

                                <td class="r3-c" align="center">

                                    <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r17-o" style="table-layout: fixed; width: 100%;">

                                        <!-- -->

                                        <tr class="nl2go-responsive-hide">

                                            <td height="50" width="25" style="font-size: 50px; line-height: 50px; background-color: #f3f1e9;">
                                                ­
                                            </td>

                                            <td height="50" style="font-size: 50px; line-height: 50px; background-color: #f3f1e9;">
                                                ­
                                            </td>

                                            <td height="50" width="25" style="font-size: 50px; line-height: 50px; background-color: #f3f1e9;">
                                                ­
                                            </td>

                                        </tr>

                                        <tr>

                                            <td class="nl2go-responsive-hide" width="25" style="font-size: 0px; line-height: 1px; background-color: #f3f1e9;">
                                                ­
                                            </td>

                                            <td class="r18-i" style="background-color: #f3f1e9;">

                                                <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">

                                                    <tr>

                                                        <th width="100%" valign="top" class="r6-c" style="font-weight: normal;">

                                                            <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">

                                                                <!-- -->

                                                                <tr>

                                                                    <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">
                                                                        ­
                                                                    </td>

                                                                    <td valign="top" class="r8-i">

                                                                        <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">

                                                                            <tr>

                                                                                <td class="r14-c" align="left">

                                                                                    <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r15-o" style="table-layout: fixed; width: 100%;">

                                                                                        <tr>

                                                                                            <td align="justify" valign="top" class="r19-i nl2go-default-textstyle" style="color: #3b3f44; font-family: arial,helvetica,sans-serif; font-size: 16px; line-height: 1.5; text-align: justify;">

                                                                                                <div>

                                                                                                    <p style="margin: 0;" v-html="message">
                                                                                                    </p>

                                                                                                </div>

                                                                                            </td>

                                                                                        </tr>

                                                                                    </table>

                                                                                </td>

                                                                            </tr>

                                                                            <tr>

                                                                                <td class="r3-c" align="center">

                                                                                    <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="180" class="r20-o" style="table-layout: fixed; width: 180px;">

                                                                                        <tr class="nl2go-responsive-hide">

                                                                                            <td height="15" style="font-size: 15px; line-height: 15px;">
                                                                                                ­
                                                                                            </td>

                                                                                        </tr>

                                                                                        <tr>

                                                                                            <td height="18" align="center" valign="top" class="r21-i nl2go-default-textstyle" style="color: #3b3f44; font-family: arial,helvetica,sans-serif; font-size: 16px; line-height: 1.5;">
                                                                                                <a class="r22-r default-button" target="_blank" data-btn="1" style="font-style: normal; font-weight: normal; line-height: 1.15; text-decoration: none; border-style: solid; display: inline-block; -webkit-text-size-adjust: none; mso-hide: all; background-color: #e6cb6d; border-color: #e6cb6d; border-radius: 4px; border-width: 0px; color: #ffffff; font-family: arial,helvetica,sans-serif; font-size: 16px;  padding-bottom: 12px; padding-left: 5px; padding-right: 5px; padding-top: 12px; width: 170px;">

                                                                                                    <p style="margin: 0;">

                                                                                                        <strong>
                                                                                                            VER REGALO
                                                                                                        </strong>

                                                                                                    </p>

                                                                                                </a>


                                                                                            </td>

                                                                                        </tr>

                                                                                        <tr class="nl2go-responsive-hide">

                                                                                            <td height="15" style="font-size: 15px; line-height: 15px;">
                                                                                                ­
                                                                                            </td>

                                                                                        </tr>

                                                                                    </table>

                                                                                </td>

                                                                            </tr>

                                                                        </table>

                                                                    </td>

                                                                    <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">
                                                                        ­
                                                                    </td>

                                                                </tr>

                                                            </table>

                                                        </th>

                                                    </tr>

                                                </table>

                                            </td>

                                            <td class="nl2go-responsive-hide" width="25" style="font-size: 0px; line-height: 1px; background-color: #f3f1e9;">
                                                ­
                                            </td>

                                        </tr>

                                        <tr class="nl2go-responsive-hide">

                                            <td height="50" width="25" style="font-size: 50px; line-height: 50px; background-color: #f3f1e9;">
                                                ­
                                            </td>

                                            <td height="50" style="font-size: 50px; line-height: 50px; background-color: #f3f1e9;">
                                                ­
                                            </td>

                                            <td height="50" width="25" style="font-size: 50px; line-height: 50px; background-color: #f3f1e9;">
                                                ­
                                            </td>

                                        </tr>

                                    </table>

                                </td>

                            </tr>

                            <tr>

                                <td class="r3-c" align="center">

                                    <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r23-o" style="background-color: #eff2f7; background-image: url('https://img.mailinblue.com/4759347/images/content_library/original/62bb09ac82ac264476727e46.png'); background-position: top; background-repeat: no-repeat; background-size: cover; font-size: 0; table-layout: fixed; width: 100%;">

                                        <!-- -->

                                        <tr class="nl2go-responsive-hide">

                                            <td height="20" style="font-size: 20px; line-height: 20px;">
                                                ­
                                            </td>

                                        </tr>

                                        <tr>

                                            <td class="r24-i">

                                                <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">

                                                    <tr>

                                                        <th width="100%" valign="top" class="r6-c" style="font-weight: normal;">

                                                            <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">

                                                                <!-- -->

                                                                <tr>

                                                                    <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">
                                                                        ­
                                                                    </td>

                                                                    <td valign="top" class="r8-i">

                                                                        <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">

                                                                            <tr>

                                                                                <td class="r14-c" align="left">

                                                                                    <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r25-o" style="table-layout: fixed; width: 100%;">

                                                                                        <tr>

                                                                                            <td align="center" valign="top" class="r26-i nl2go-default-textstyle" style="color: #3b3f44; font-family: arial,helvetica,sans-serif; font-size: 16px; line-height: 1.15; text-align: center;">

                                                                                                <div>

                                                                                                    <h3 class="default-heading3" style="margin: 0; color: #1F2D3D; font-family: arial,helvetica,sans-serif; font-size: 24px;">

                                                                                                        <span style="color: #474747; font-family: 'Trebuchet ms', helvetica, sans-serif; font-size: 16px;">

                                                                                                            <strong>
                                                                                                                REDES SOCIALES
                                                                                                            </strong>

                                                                                                        </span>

                                                                                                    </h3>

                                                                                                </div>

                                                                                            </td>

                                                                                        </tr>

                                                                                    </table>

                                                                                </td>

                                                                            </tr>

                                                                            <tr>

                                                                                <td class="r27-c">

                                                                                    <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="570" class="r7-o" style="table-layout: fixed; width: 570px;">

                                                                                        <!-- -->

                                                                                        <tr>

                                                                                            <td valign="top" class="">

                                                                                                <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">

                                                                                                    <tr>

                                                                                                        <td class="r28-c" align="center" style="display: inline-block;">

                                                                                                            <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="570" class="r29-o" style="table-layout: fixed; width: 570px;">

                                                                                                                <!-- -->

                                                                                                                <tr class="nl2go-responsive-hide">

                                                                                                                    <td height="15" width="214" style="font-size: 15px; line-height: 15px;">
                                                                                                                        ­
                                                                                                                    </td>

                                                                                                                    <td height="15" style="font-size: 15px; line-height: 15px;">
                                                                                                                        ­
                                                                                                                    </td>

                                                                                                                    <td height="15" width="215" style="font-size: 15px; line-height: 15px;">
                                                                                                                        ­
                                                                                                                    </td>

                                                                                                                </tr>

                                                                                                                <tr>

                                                                                                                    <td class="nl2go-responsive-hide" width="214" style="font-size: 0px; line-height: 1px;">
                                                                                                                        ­
                                                                                                                    </td>

                                                                                                                    <td class="r30-i">

                                                                                                                        <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">

                                                                                                                            <tr>

                                                                                                                                <th width="47" valign="" class="r31-c mobshow resp-table" style="font-weight: normal;">

                                                                                                                                    <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r32-o" style="table-layout: fixed; width: 100%;">

                                                                                                                                        <!-- -->

                                                                                                                                        <tr class="nl2go-responsive-hide">

                                                                                                                                            <td height="5" style="font-size: 5px; line-height: 5px;">
                                                                                                                                                ­
                                                                                                                                            </td>

                                                                                                                                            <td height="5" width="15" style="font-size: 5px; line-height: 5px;">
                                                                                                                                                ­
                                                                                                                                            </td>

                                                                                                                                        </tr>

                                                                                                                                        <tr>

                                                                                                                                            <td class="r33-i" style="font-size: 0px; line-height: 0px;">

                                                                                                                                                <img src="https://sendinblue-templates.s3.eu-west-3.amazonaws.com/icons/rounded_bw/facebook_32px.png" width="32" border="0" class="" style="display: block; width: 100%;">

                                                                                                                                            </td>

                                                                                                                                            <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">
                                                                                                                                                ­
                                                                                                                                            </td>

                                                                                                                                        </tr>

                                                                                                                                        <tr class="nl2go-responsive-hide">

                                                                                                                                            <td height="5" style="font-size: 5px; line-height: 5px;">
                                                                                                                                                ­
                                                                                                                                            </td>

                                                                                                                                            <td height="5" width="15" style="font-size: 5px; line-height: 5px;">
                                                                                                                                                ­
                                                                                                                                            </td>

                                                                                                                                        </tr>

                                                                                                                                    </table>

                                                                                                                                </th>

                                                                                                                                <th width="47" valign="" class="r31-c mobshow resp-table" style="font-weight: normal;">

                                                                                                                                    <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r32-o" style="table-layout: fixed; width: 100%;">

                                                                                                                                        <!-- -->

                                                                                                                                        <tr class="nl2go-responsive-hide">

                                                                                                                                            <td height="5" style="font-size: 5px; line-height: 5px;">
                                                                                                                                                ­
                                                                                                                                            </td>

                                                                                                                                            <td height="5" width="15" style="font-size: 5px; line-height: 5px;">
                                                                                                                                                ­
                                                                                                                                            </td>

                                                                                                                                        </tr>

                                                                                                                                        <tr>

                                                                                                                                            <td class="r33-i" style="font-size: 0px; line-height: 0px;">

                                                                                                                                                <img src="https://sendinblue-templates.s3.eu-west-3.amazonaws.com/icons/rounded_bw/linkedin_32px.png" width="32" border="0" class="" style="display: block; width: 100%;">

                                                                                                                                            </td>

                                                                                                                                            <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">
                                                                                                                                                ­
                                                                                                                                            </td>

                                                                                                                                        </tr>

                                                                                                                                        <tr class="nl2go-responsive-hide">

                                                                                                                                            <td height="5" style="font-size: 5px; line-height: 5px;">
                                                                                                                                                ­
                                                                                                                                            </td>

                                                                                                                                            <td height="5" width="15" style="font-size: 5px; line-height: 5px;">
                                                                                                                                                ­
                                                                                                                                            </td>

                                                                                                                                        </tr>

                                                                                                                                    </table>

                                                                                                                                </th>

                                                                                                                                <th width="47" valign="" class="r31-c mobshow resp-table" style="font-weight: normal;">

                                                                                                                                    <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r32-o" style="table-layout: fixed; width: 100%;">

                                                                                                                                        <!-- -->

                                                                                                                                        <tr class="nl2go-responsive-hide">

                                                                                                                                            <td height="5" style="font-size: 5px; line-height: 5px;">
                                                                                                                                                ­
                                                                                                                                            </td>

                                                                                                                                            <td height="5" width="15" style="font-size: 5px; line-height: 5px;">
                                                                                                                                                ­
                                                                                                                                            </td>

                                                                                                                                        </tr>

                                                                                                                                        <tr>

                                                                                                                                            <td class="r33-i" style="font-size: 0px; line-height: 0px;">

                                                                                                                                                <img src="https://sendinblue-templates.s3.eu-west-3.amazonaws.com/icons/rounded_bw/instagram_32px.png" width="32" border="0" class="" style="display: block; width: 100%;">

                                                                                                                                            </td>

                                                                                                                                            <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">
                                                                                                                                                ­
                                                                                                                                            </td>

                                                                                                                                        </tr>

                                                                                                                                        <tr class="nl2go-responsive-hide">

                                                                                                                                            <td height="5" style="font-size: 5px; line-height: 5px;">
                                                                                                                                                ­
                                                                                                                                            </td>

                                                                                                                                            <td height="5" width="15" style="font-size: 5px; line-height: 5px;">
                                                                                                                                                ­
                                                                                                                                            </td>

                                                                                                                                        </tr>

                                                                                                                                    </table>

                                                                                                                                </th>

                                                                                                                            </tr>

                                                                                                                        </table>

                                                                                                                    </td>

                                                                                                                    <td class="nl2go-responsive-hide" width="215" style="font-size: 0px; line-height: 1px;">
                                                                                                                        ­
                                                                                                                    </td>

                                                                                                                </tr>

                                                                                                                <tr class="nl2go-responsive-hide">

                                                                                                                    <td height="15" width="214" style="font-size: 15px; line-height: 15px;">
                                                                                                                        ­
                                                                                                                    </td>

                                                                                                                    <td height="15" style="font-size: 15px; line-height: 15px;">
                                                                                                                        ­
                                                                                                                    </td>

                                                                                                                    <td height="15" width="215" style="font-size: 15px; line-height: 15px;">
                                                                                                                        ­
                                                                                                                    </td>

                                                                                                                </tr>

                                                                                                            </table>

                                                                                                        </td>

                                                                                                    </tr>

                                                                                                </table>

                                                                                            </td>

                                                                                        </tr>

                                                                                    </table>

                                                                                </td>

                                                                            </tr>

                                                                            <tr>

                                                                                <td class="r3-c" align="center">

                                                                                    <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="400" class="r17-o" style="table-layout: fixed; width: 400px;">

                                                                                        <tr>

                                                                                            <td align="center" valign="top" class="r34-i nl2go-default-textstyle" style="font-family: arial,helvetica,sans-serif; color: #3b3f44; font-size: 18px; line-height: 1.5; text-align: center;">

                                                                                                <div>

                                                                                                    <p style="margin: 0; font-size: 14px;">

                                                                                                        <span style="color: #474747; font-family: 'Trebuchet ms', helvetica, sans-serif;">

                                                                                                            <strong>
                                                                                                                homiup © 2022 | CRM RED
                                                                                                            </strong>

                                                                                                        </span>

                                                                                                    </p>

                                                                                                </div>

                                                                                            </td>

                                                                                        </tr>

                                                                                        <tr class="nl2go-responsive-hide">

                                                                                            <td height="20" style="font-size: 20px; line-height: 20px;">
                                                                                                ­
                                                                                            </td>

                                                                                        </tr>

                                                                                    </table>

                                                                                </td>

                                                                            </tr>

                                                                        </table>

                                                                    </td>

                                                                    <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">
                                                                        ­
                                                                    </td>

                                                                </tr>

                                                            </table>

                                                        </th>

                                                    </tr>

                                                </table>

                                            </td>

                                        </tr>

                                        <tr class="nl2go-responsive-hide">

                                            <td height="20" style="font-size: 20px; line-height: 20px;">
                                                ­
                                            </td>

                                        </tr>

                                    </table>

                                </td>

                            </tr>

                        </table>

                    </td>

                </tr>

            </table>

        </td>

    </tr>

</table>

</body>
</template>

<script>
export default{
    props:{
        user:{
            type:Object,
            default:null
        },
        message:{
            type:String
        }
    }
}
</script>

<style type="text/css" emogrify="no" scoped>
#outlook a {
    padding: 0;
}

.ExternalClass {
    width: 100%;
}

.ExternalClass,
.ExternalClass p,
.ExternalClass span,
.ExternalClass font,
.ExternalClass td,
.ExternalClass div {
    line-height: 100%;
}

table td {
    border-collapse: collapse;
    mso-line-height-rule: exactly;
}

.editable.image {
    font-size: 0 !important;
    line-height: 0 !important;
}

.nl2go_preheader {
    display: none !important;
    mso-hide: all !important;
    mso-line-height-rule: exactly;
    visibility: hidden !important;
    line-height: 0px !important;
    font-size: 0px !important;
}

body {
    width: 100% !important;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    margin: 0;
    padding: 0;
}

img {
    outline: none;
    text-decoration: none;
    -ms-interpolation-mode: bicubic;
}

a img {
    border: none;
}

table {
    border-collapse: collapse;
    mso-table-lspace: 0pt;
    mso-table-rspace: 0pt;
}

th {
    font-weight: normal;
    text-align: left;
}

*[class="gmail-fix"] {
    display: none !important;
}
</style>

<style type="text/css" emogrify="no" scoped>
@media (max-width: 600px) {
    .gmx-killpill {
        content: ' \03D1';
    }
}
</style>

<style type="text/css" emogrify="no" scoped>
@media (max-width: 600px) {
    .gmx-killpill {
        content: ' \03D1';
    }

    .r0-c {
        box-sizing: border-box !important;
        text-align: center !important;
        valign: top !important;
        width: 320px !important
    }

    .r1-o {
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        width: 320px !important
    }

    .r2-i {
        background-color: #f6f6f6 !important
    }

    .r3-c {
        box-sizing: border-box !important;
        text-align: center !important;
        valign: top !important;
        width: 100% !important
    }

    .r4-o {
        background-image: url('https://img.mailinblue.com/4759347/images/content_library/original/62bb02992487e54c3e7c7e36.png') !important;
        background-size: contain !important;
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        width: 100% !important
    }

    .r5-i {
        padding-bottom: 0px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        padding-top: 20px !important
    }

    .r6-c {
        box-sizing: border-box !important;
        display: block !important;
        ver-align: top !important;
        width: 100% !important
    }

    .r7-o {
        border-style: solid !important;
        width: 100% !important
    }

    .r8-i {
        padding-left: 0px !important;
        padding-right: 0px !important
    }

    .r9-c {
        box-sizing: border-box !important;
        text-align: center !important;
        valign: top !important;
        width: 150px !important
    }

    .r10-o {
        background-size: auto !important;
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        width: 150px !important
    }

    .r11-i {
        padding-bottom: 0px !important;
        padding-top: 120px !important
    }

    .r12-o {
        background-size: auto !important;
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        width: 100% !important
    }

    .r13-i {
        padding-bottom: 0px !important;
        padding-top: 20px !important
    }

    .r14-c {
        box-sizing: border-box !important;
        text-align: left !important;
        valign: top !important;
        width: 100% !important
    }

    .r15-o {
        border-style: solid !important;
        margin: 0 auto 0 0 !important;
        width: 100% !important
    }

    .r16-i {
        padding-top: 10px !important;
        text-align: center !important
    }

    .r17-o {
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
        width: 100% !important
    }

    .r18-i {
        background-color: #f3f1e9 !important;
        padding-bottom: 25px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        padding-top: 25px !important
    }

    .r19-i {
        padding-bottom: 0px !important;
        padding-top: 0px !important;
        text-align: justify !important
    }

    .r20-o {
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        margin-bottom: 15px !important;
        margin-top: 15px !important;
        width: 100% !important
    }

    .r21-i {
        text-align: center !important
    }

    .r22-r {
        background-color: #e6cb6d !important;
        border-radius: 4px !important;
        border-width: 0px !important;
        box-sizing: border-box;
        height: initial !important;
        padding-bottom: 12px !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
        padding-top: 12px !important;
        text-align: center !important;
        width: 100% !important
    }

    .r23-o {
        background-image: url('https://img.mailinblue.com/4759347/images/content_library/original/62bb09ac82ac264476727e46.png') !important;
        background-size: cover !important;
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        width: 100% !important
    }

    .r24-i {
        padding-bottom: 20px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        padding-top: 20px !important
    }

    .r25-o {
        border-style: solid !important;
        margin: 0 auto 0 0 !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
        width: 100% !important
    }

    .r26-i {
        padding-bottom: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 0px !important;
        text-align: center !important
    }

    .r27-c {
        box-sizing: border-box !important;
        width: 100% !important
    }

    .r28-c {
        box-sizing: border-box !important;
        text-align: center !important;
        width: 100% !important
    }

    .r29-o {
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        width: 100% !important
    }

    .r30-i {
        font-size: 0px !important;
        padding-bottom: 15px !important;
        padding-left: 85px !important;
        padding-right: 85px !important;
        padding-top: 15px !important
    }

    .r31-c {
        box-sizing: border-box !important;
        width: 32px !important
    }

    .r32-o {
        border-style: solid !important;
        margin-right: 8px !important;
        width: 32px !important
    }

    .r33-i {
        padding-bottom: 5px !important;
        padding-top: 5px !important
    }

    .r34-i {
        color: #3b3f44 !important;
        padding-bottom: 20px !important;
        padding-top: 0px !important;
        text-align: center !important
    }

    .r35-i {
        padding-bottom: 15px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 0px !important
    }

    .r36-c {
        box-sizing: border-box !important;
        text-align: center !important;
        valign: top !important;
        width: 129px !important
    }

    .r37-o {
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        width: 129px !important
    }

    body {
        -webkit-text-size-adjust: none
    }

    .nl2go-responsive-hide {
        display: none
    }

    .nl2go-body-table {
        min-width: unset !important
    }

    .mobshow {
        height: auto !important;
        overflow: visible !important;
        max-height: unset !important;
        visibility: visible !important;
        border: none !important
    }

    .resp-table {
        display: inline-table !important
    }

    .magic-resp {
        display: table-cell !important
    }
}
</style>

<!--[if !mso]>

<!-->

<style type="text/css" emogrify="no" scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat");
</style>

<!--
<![endif]-->

<style type="text/css" scoped>
p,
h1,
h2,
h3,
h4,
ol,
ul {
    margin: 0;
}

a,
a:link {
    color: #0092ff;
    text-decoration: underline
}

.nl2go-default-textstyle {
    color: #3b3f44;
    font-family: arial, helvetica, sans-serif;
    font-size: 16px;
    line-height: 1.5
}

.default-button {
    border-radius: 4px;
    color: #ffffff;
    font-family: arial, helvetica, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.15;
    text-decoration: none;
    width: 50%
}

.default-heading1 {
    color: #1F2D3D;
    font-family: arial, helvetica, sans-serif;
    font-size: 36px
}

.default-heading2 {
    color: #1F2D3D;
    font-family: arial, helvetica, sans-serif;
    font-size: 32px
}

.default-heading3 {
    color: #1F2D3D;
    font-family: arial, helvetica, sans-serif;
    font-size: 24px
}

.default-heading4 {
    color: #1F2D3D;
    font-family: arial, helvetica, sans-serif;
    font-size: 18px
}

a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: inherit !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
}

.no-show-for-you {
    border: none;
    display: none;
    float: none;
    font-size: 0;
    height: 0;
    line-height: 0;
    max-height: 0;
    overflow: hidden;
    table-layout: fixed;
    visibility: hidden;
    width: 0;
}
</style>


<style type="text/css" scoped>
a:link {
    color: #0092ff;
    text-decoration: underline
}
</style>