import { render, staticRenderFns } from "./fichaDayFather.vue?vue&type=template&id=2ff37e2e&scoped=true&"
import script from "./fichaDayFather.vue?vue&type=script&lang=js&"
export * from "./fichaDayFather.vue?vue&type=script&lang=js&"
import style0 from "./fichaDayFather.vue?vue&type=style&index=0&id=2ff37e2e&prod&emogrify=no&scoped=true&lang=css&"
import style1 from "./fichaDayFather.vue?vue&type=style&index=1&id=2ff37e2e&prod&emogrify=no&scoped=true&lang=css&"
import style2 from "./fichaDayFather.vue?vue&type=style&index=2&id=2ff37e2e&prod&emogrify=no&scoped=true&lang=css&"
import style3 from "./fichaDayFather.vue?vue&type=style&index=3&id=2ff37e2e&prod&emogrify=no&scoped=true&lang=css&"
import style4 from "./fichaDayFather.vue?vue&type=style&index=4&id=2ff37e2e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ff37e2e",
  null
  
)

export default component.exports