<template>
<body bgcolor="#ffffff" text="#3b3f44" link="#0092ff" yahoo="fix" style="background-color: #ffffff;">
	<table cellspacing="0" cellpadding="0" border="0" role="presentation" class="nl2go-body-table" width="100%" style="background-color: #ffffff; width: 100%;">
		<tr>
			<td align="center" class="r0-c">
				<table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" class="r1-o" style="table-layout: fixed; width: 600px;">
					<tr>
						<td valign="top" class="r2-i" style="background-color: #ffffff;">
							<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
								<tr>
									<td class="r3-c" align="center">
										<table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r4-o" style="background-color: #ffc1c1; background-image: url('https://img.mailinblue.com/4759347/images/content_library/original/62c6e1292015ca65bf072bec.png'); background-position: top; background-repeat: no-repeat; background-size: auto; font-size: 0; table-layout: fixed; width: 100%;">
											<!-- -->
											<tr class="nl2go-responsive-hide">
												<td height="70" style="font-size: 70px; line-height: 70px;">
													­
												</td>
											</tr>
											<tr>
												<td class="r5-i">
													<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
														<tr>
															<th width="100%" valign="top" class="r6-c" style="font-weight: normal;">
																<table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
																	<!-- -->
																	<tr>
																		<td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">
																			­
																		</td>
																		<td valign="top" class="r8-i">
																			<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
																				<tr>
																					<td class="r9-c" align="center">
																						<table cellspacing="0" cellpadding="0" border="0" role="presentation" width="180" class="r10-o" style="table-layout: fixed; width: 180px;">
																							<tr>
																								<td class="r11-i" style="font-size: 0px; line-height: 0px;" v-if="user.empresa">
																									<img :src="(user.empresa.logo)?user.empresa.logo :'https://img.mailinblue.com/4759347/images/content_library/original/62bb032894f9533066676e5c.png'" width="180" border="0" class="" style="display: block; width: 100%;">
                                                                                                </td>
                                                                                            </tr>
                                                                                        </table>
																					</td>
																				</tr>
																			</table>
																		</td>
																	</tr>
																	<tr>
																		<td class="r3-c" align="center">
																			<table cellspacing="0" cellpadding="0" border="0" role="presentation" width="600" class="r12-o" style="table-layout: fixed; width: 600px;">
																				<tr class="nl2go-responsive-hide">
																					<td height="30" style="font-size: 30px; line-height: 30px;">
																						­
																					</td>
																				</tr>
																				<tr>
																					<td class="r13-i" style="font-size: 0px; line-height: 0px;">
																						<img src="https://img.mailinblue.com/4759347/images/content_library/original/62c6dfbeedc54556de4c5889.png" width="300" border="0" class="" style="display: block; width: 300px; margin: auto;">
																					</td>
																				</tr>
																			</table>
																		</td>
																	</tr>
																</table>
                                                            </th>
                                                        </tr>
                                                    </table>
												</td>
												<!-- <td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">
													­
												</td> -->
											</tr>
										</table>
									</td>
								</tr>
							</table>
						</td>
					</tr>
				</table>
			</td>
		</tr>
		<tr>
			<td class="r3-c" align="center" >
				<table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r14-o" style="background-color: #ffc1c1; background-image: url('https://img.mailinblue.com/4759347/images/content_library/original/62c614ba8ca9a907275c19d6.png'); background-position: center; background-repeat: repeat-y; background-size: auto; font-size: 0; table-layout: fixed; width: 100%;" >
					<!-- -->
					<tr class="nl2go-responsive-hide">
						<td height="30" width="25" style="font-size: 30px; line-height: 30px;">
							­
						</td>
						<td height="30" style="font-size: 30px; line-height: 30px;">
							­
						</td>
						<td height="30" width="25" style="font-size: 30px; line-height: 30px;">
							­
						</td>
					</tr>
					<tr>
						<td class="nl2go-responsive-hide" width="25" style="font-size: 0px; line-height: 1px;">
							­
						</td>
						<td class="r15-i">
							<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
								<tr>
									<th width="100%" valign="top" class="r6-c" style="font-weight: normal;">
										<table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
											<!-- -->
											<tr>
												<td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">
													­
												</td>
												<td valign="top" class="r8-i">
													<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
														<tr>
															<td class="r3-c" align="center">
																<table cellspacing="0" cellpadding="0" border="0" role="presentation" width="450" class="r12-o" style="table-layout: fixed; width: 450px;">
																	<tr>
																		<td align="center" valign="top" class="r16-i nl2go-default-textstyle" style="color: #3b3f44; font-family: arial,helvetica,sans-serif; font-size: 16px; line-height: 2; text-align: center;">
																			<div>
																				<p style="margin: 0;">
																					En este día recordamos lo importante y maravilloso 
																				</p>
																				<p style="margin: 0;">
																					que eres para
																					<span style="color: #E15959;">
																						<strong>
																							CRM RED.
																						</strong>
																					</span>
																				</p>
																			</div>
																		</td>
																	</tr>
																</table>
															</td>
														</tr>
														<tr>
															<td class="r3-c" align="center">
																<table cellspacing="0" cellpadding="0" border="0" role="presentation" width="450" class="r17-o" style="table-layout: fixed; width: 450px;">
																	<tr class="nl2go-responsive-hide">
																		<td height="25" style="font-size: 25px; line-height: 25px;">
																			­
																		</td>
																	</tr>
																	<tr>
																		<td align="center" valign="top" class="r16-i nl2go-default-textstyle" style="color: #3b3f44; font-family: arial,helvetica,sans-serif; font-size: 16px; line-height: 2; text-align: center;">
																			<div>
																				<p style="margin: 0;" v-html="message">
																				</p>
																			</div>
																		</td>
																	</tr>
																</table>
															</td>
														</tr>
														<tr>
															<td class="r3-c" align="center">
																<table cellspacing="0" cellpadding="0" border="0" role="presentation" width="450" class="r17-o" style="table-layout: fixed; width: 450px;">
																	<tr class="nl2go-responsive-hide">
																		<td height="25" style="font-size: 25px; line-height: 25px;">
																			­
																		</td>
																	</tr>
																	<tr>
																		<td align="center" valign="top" class="r16-i nl2go-default-textstyle" style="color: #3b3f44; font-family: arial,helvetica,sans-serif; font-size: 16px; line-height: 2; text-align: center;">
																			<div>
																				<p style="margin: 0;">
																					<span style="color: #e15959; font-size: 18px;">
																						<strong>
																							¡FELIZ DÍA DE SAN VALENTÍN!
																						</strong>
																					</span>
																				</p>
																			</div>
																		</td>
																	</tr>
																</table>
															</td>
														</tr>
													</table>
												</td>
												<td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">
													­
												</td>
											</tr>
										</table>
									</th>
								</tr>
							</table>
						</td>
						<td class="nl2go-responsive-hide" width="25" style="font-size: 0px; line-height: 1px;">
							­
						</td>
					</tr>
					<tr class="nl2go-responsive-hide">
						<td height="30" width="25" style="font-size: 30px; line-height: 30px;">
							­
						</td>
						<td height="30" style="font-size: 30px; line-height: 30px;">
							­
						</td>
						<td height="30" width="25" style="font-size: 30px; line-height: 30px;">
							­
						</td>
					</tr>
				</table>
			</td>
		</tr>
		<tr>
			<td class="r3-c" align="center">
				<table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r18-o" style="background-color: #ffc1c1; background-image: url('https://img.mailinblue.com/4759347/images/content_library/original/62c614ba6ad9e0169131cdb7.png'); background-position: top; background-repeat: no-repeat; background-size: auto; font-size: 0; table-layout: fixed; width: 100%;">
					<!-- -->
					<tr class="nl2go-responsive-hide">
						<td height="40" width="25" style="font-size: 40px; line-height: 40px;">
							­
						</td>
						<td height="40" style="font-size: 40px; line-height: 40px;">
							­
						</td>
						<td height="40" width="25" style="font-size: 40px; line-height: 40px;">
							­
						</td>
					</tr>
					<tr>
						<td class="nl2go-responsive-hide" width="25" style="font-size: 0px; line-height: 1px;">
							­
						</td>
						<td class="r19-i">
							<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
								<tr>
									<th width="100%" valign="top" class="r6-c" style="font-weight: normal;">
										<table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r7-o" style="table-layout: fixed; width: 100%;">
											<!-- -->
											<tr>
												<td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">
													­
												</td>
												<td valign="top" class="r8-i">
													<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
														<tr>
															<td class="r20-c" align="left">
																<table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r21-o" style="table-layout: fixed; width: 100%;">
																	<tr>
																		<td align="center" valign="top" class="r16-i nl2go-default-textstyle" style="color: #3b3f44; font-family: arial,helvetica,sans-serif; font-size: 16px; line-height: 1.15; text-align: center;">
																			<div>
																				<p style="margin: 0;">
																					<span style="font-family: 'Trebuchet ms', helvetica, sans-serif; font-size: 14px;">
																						<strong>
																							REDES
																						</strong>
																					</span>
																					<span style="font-family: 'Trebuchet ms', helvetica, sans-serif;">
																						<strong>
																							SOCIALES
																						</strong>
																					</span>
																				</p>
																			</div>
																		</td>
																	</tr>
																</table>
															</td>
														</tr>
														<tr>
															<td class="r22-c">
																<table cellspacing="0" cellpadding="0" border="0" role="presentation" width="520" class="r7-o" style="table-layout: fixed; width: 520px;">
																	<!-- -->
																	<tr>
																		<td valign="top" class="">
																			<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
																				<tr>
																					<td class="r23-c" align="center" style="display: inline-block;">
																						<table cellspacing="0" cellpadding="0" border="0" role="presentation" width="520" class="r24-o" style="table-layout: fixed; width: 520px;">
																							<!-- -->
																							<tr class="nl2go-responsive-hide">
																								<td height="15" width="182" style="font-size: 15px; line-height: 15px;">
																									­
																								</td>
																								<td height="15" style="font-size: 15px; line-height: 15px;">
																									­
																								</td>
																								<td height="15" width="182" style="font-size: 15px; line-height: 15px;">
																									­
																								</td>
																							</tr>
																							<tr>
																								<td class="nl2go-responsive-hide" width="182" style="font-size: 0px; line-height: 1px;">
																									­
																								</td>
																								<td class="r25-i">
																									<table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
																										<tr>
																											<th width="52" valign="" class="r26-c mobshow resp-table" style="font-weight: normal;">
																												<table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r27-o" style="table-layout: fixed; width: 100%;">
																													<!-- -->
																													<tr class="nl2go-responsive-hide">
																														<td height="5" style="font-size: 5px; line-height: 5px;">
																															­
																														</td>
																														<td height="5" width="20" style="font-size: 5px; line-height: 5px;">
																															­
																														</td>
																													</tr>
																													<tr>
																														<td class="r28-i" style="font-size: 0px; line-height: 0px;">
																															<a img src="https://sendinblue-templates.s3.eu-west-3.amazonaws.com/icons/rounded_colored/facebook_32px.png" width="32" border="0" class="" style="display: block; width: 100%;">
																															</a>
																														</td>
																														<td class="nl2go-responsive-hide" width="20" style="font-size: 0px; line-height: 1px;">
																															­
																														</td>
																													</tr>
																													<tr class="nl2go-responsive-hide">
																														<td height="5" style="font-size: 5px; line-height: 5px;">
																															­
																														</td>
																														<td height="5" width="20" style="font-size: 5px; line-height: 5px;">
																															­
																														</td>

																													</tr>
																												</table>
																											</th>
																											<th width="52" valign="" class="r26-c mobshow resp-table" style="font-weight: normal;">
																												<table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r27-o" style="table-layout: fixed; width: 100%;">
																													<!-- -->
																													<tr class="nl2go-responsive-hide">
																														<td height="5" style="font-size: 5px; line-height: 5px;">
																															­
																														</td>
																														<td height="5" width="20" style="font-size: 5px; line-height: 5px;">
																															­
																														</td>
																													</tr>
																													<tr>
																														<td class="r28-i" style="font-size: 0px; line-height: 0px;">
																															<img src="https://sendinblue-templates.s3.eu-west-3.amazonaws.com/icons/rounded_colored/linkedin_32px.png" width="32" border="0" class="" style="display: block; width: 100%;">
																														</td>
																														<td class="nl2go-responsive-hide" width="20" style="font-size: 0px; line-height: 1px;">
																															­
																														</td>
																													</tr>
																													<tr class="nl2go-responsive-hide">
																														<td height="5" style="font-size: 5px; line-height: 5px;">
																															­
																														</td>
																														<td height="5" width="20" style="font-size: 5px; line-height: 5px;">
																															­
																														</td>
																													</tr>
																												</table>
																											</th>
																											<th width="52" valign="" class="r26-c mobshow resp-table" style="font-weight: normal;">
																												<table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" class="r27-o" style="table-layout: fixed; width: 100%;">
																													<!-- -->
																													<tr class="nl2go-responsive-hide">
																														<td height="5" style="font-size: 5px; line-height: 5px;">
																															­
																														</td>
																														<td height="5" width="20" style="font-size: 5px; line-height: 5px;">
																															­
																														</td>
																													</tr>
																													<tr>
																														<td class="r28-i" style="font-size: 0px; line-height: 0px;">
																															<img src="https://sendinblue-templates.s3.eu-west-3.amazonaws.com/icons/rounded_colored/instagram_32px.png" width="32" border="0" class="" style="display: block; width: 100%;">
																														</td>
																														<td class="nl2go-responsive-hide" width="20" style="font-size: 0px; line-height: 1px;">
																															­
																														</td>
																													</tr>
																													<tr class="nl2go-responsive-hide">
																														<td height="5" style="font-size: 5px; line-height: 5px;">
																															­
																														</td>
																														<td height="5" width="20" style="font-size: 5px; line-height: 5px;">
																															­
																														</td>
																													</tr>
																												</table>
																											</th>
																										</tr>
																									</table>
																								</td>
																								<td class="nl2go-responsive-hide" width="182" style="font-size: 0px; line-height: 1px;">
																									­
																								</td>
																							</tr>
																							<tr class="nl2go-responsive-hide">
																								<td height="15" width="182" style="font-size: 15px; line-height: 15px;">
																									­
																								</td>
																								<td height="15" style="font-size: 15px; line-height: 15px;">
																									­
																								</td>
																								<td height="15" width="182" style="font-size: 15px; line-height: 15px;">
																									­
																								</td>
																							</tr>
																						</table>
																					</td>
																				</tr>
																			</table>
																		</td>
																	</tr>
																</table>
															</td>
														</tr>
														<tr>
															<td class="r3-c" align="center">
																<table cellspacing="0" cellpadding="0" border="0" role="presentation" width="400" class="r29-o" style="table-layout: fixed; width: 400px;">
																	<tr>
																		<td align="center" valign="top" class="r30-i nl2go-default-textstyle" style="font-family: arial,helvetica,sans-serif; color: #3b3f44; font-size: 18px; line-height: 1.5; text-align: center;">
																			<div>
																				<p style="margin: 0; font-size: 14px;">
																					<span style="font-family: 'Trebuchet ms', helvetica, sans-serif;">
																						<strong>
																							CRMRED © 2022 | CRM RED
																						</strong>
																					</span>
																				</p>
																			</div>
																		</td>
																	</tr>
																	<tr class="nl2go-responsive-hide">
																		<td height="30" style="font-size: 30px; line-height: 30px;">
																			­
																		</td>
																	</tr>
																</table>
															</td>
														</tr>
													</table>
												</td>
												<td class="nl2go-responsive-hide" width="15" style="font-size: 0px; line-height: 1px;">
													­
												</td>
											</tr>
										</table>
									</th>
								</tr>
							</table>
						</td>
						<td class="nl2go-responsive-hide" width="25" style="font-size: 0px; line-height: 1px;">
							­
						</td>
					</tr>
					<tr class="nl2go-responsive-hide">
						<td height="30" width="25" style="font-size: 30px; line-height: 30px;">
							­
						</td>
						<td height="30" style="font-size: 30px; line-height: 30px;">
							­
						</td>
						<td height="30" width="25" style="font-size: 30px; line-height: 30px;">
							­
						</td>
					</tr>
				</table>
			</td>
		</tr>
	</table>
</body>
</template>

<script>
export default{
    props:{
        user:{
            type:Object,
            default:null
        },
        message:{
            type:String
        }
    }
}
</script>

<style type="text/css" emogrify="no" scoped>
#outlook a {
    padding: 0;
}

.ExternalClass {
    width: 100%;
}

.ExternalClass,
.ExternalClass p,
.ExternalClass span,
.ExternalClass font,
.ExternalClass td,
.ExternalClass div {
    line-height: 100%;
}

table td {
    border-collapse: collapse;
    mso-line-height-rule: exactly;
}

.editable.image {
    font-size: 0 !important;
    line-height: 0 !important;
}

.nl2go_preheader {
    display: none !important;
    mso-hide: all !important;
    mso-line-height-rule: exactly;
    visibility: hidden !important;
    line-height: 0px !important;
    font-size: 0px !important;
}

body {
    width: 100% !important;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    margin: 0;
    padding: 0;
}

img {
    outline: none;
    text-decoration: none;
    -ms-interpolation-mode: bicubic;
}

a img {
    border: none;
}

table {
    border-collapse: collapse;
    mso-table-lspace: 0pt;
    mso-table-rspace: 0pt;
}

th {
    font-weight: normal;
    text-align: left;
}

*[class="gmail-fix"] {
    display: none !important;
}
</style>
<style type="text/css" emogrify="no" scoped>
@media (max-width: 600px) {
    .gmx-killpill {
        content: ' \03D1';
    }
}
</style>
<style type="text/css" emogrify="no" scoped>
@media (max-width: 600px) {
    .gmx-killpill {
        content: ' \03D1';
    }

    .r0-c {
        box-sizing: border-box !important;
        text-align: center !important;
        valign: top !important;
        width: 320px !important
    }

    .r1-o {
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        width: 320px !important
    }

    .r2-i {
        background-color: #ffffff !important
    }

    .r3-c {
        box-sizing: border-box !important;
        text-align: center !important;
        valign: top !important;
        width: 100% !important
    }

    .r4-o {
        background-image: url('https://img.mailinblue.com/4759347/images/content_library/original/62c6e1292015ca65bf072bec.png') !important;
        background-size: auto !important;
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        width: 100% !important
    }

    .r5-i {
        padding-bottom: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 70px !important
    }

    .r6-c {
        box-sizing: border-box !important;
        display: block !important;
        valign: top !important;
        width: 100% !important
    }

    .r7-o {
        border-style: solid !important;
        width: 100% !important
    }

    .r8-i {
        padding-left: 0px !important;
        padding-right: 0px !important
    }

    .r9-c {
        box-sizing: border-box !important;
        text-align: center !important;
        valign: top !important;
        width: 160px !important
    }

    .r10-o {
        background-size: auto !important;
        border-bottom-width: 0px !important;
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
        width: 160px !important
    }

    .r11-i {
        padding-bottom: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 0px !important
    }

    .r12-o {
        background-size: auto !important;
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
        width: 100% !important
    }

    .r13-i {
        padding-bottom: 0px !important;
        padding-top: 30px !important
    }

    .r14-o {
        background-image: url('https://img.mailinblue.com/4759347/images/content_library/original/62c614ba8ca9a907275c19d6.png') !important;
        background-size: auto !important;
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
        width: 100% !important
    }

    .r15-i {
        padding-bottom: 30px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        padding-top: 30px !important
    }

    .r16-i {
        padding-bottom: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 0px !important;
        text-align: center !important
    }

    .r17-o {
        background-size: auto !important;
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        margin-bottom: 0px !important;
        margin-top: 25px !important;
        width: 100% !important
    }

    .r18-o {
        background-image: url('https://img.mailinblue.com/4759347/images/content_library/original/62c614ba6ad9e0169131cdb7.png') !important;
        background-size: auto !important;
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
        width: 100% !important
    }

    .r19-i {
        padding-bottom: 30px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 40px !important
    }

    .r20-c {
        box-sizing: border-box !important;
        text-align: left !important;
        valign: top !important;
        width: 100% !important
    }

    .r21-o {
        border-style: solid !important;
        margin: 0 auto 0 0 !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
        width: 100% !important
    }

    .r22-c {
        box-sizing: border-box !important;
        width: 100% !important
    }

    .r23-c {
        box-sizing: border-box !important;
        text-align: center !important;
        width: 100% !important
    }

    .r24-o {
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        width: 100% !important
    }

    .r25-i {
        font-size: 0px !important;
        padding-bottom: 15px !important;
        padding-left: 100px !important;
        padding-right: 100px !important;
        padding-top: 15px !important
    }

    .r26-c {
        box-sizing: border-box !important;
        width: 32px !important
    }

    .r27-o {
        border-style: solid !important;
        margin-right: 8px !important;
        width: 32px !important
    }

    .r28-i {
        padding-bottom: 5px !important;
        padding-top: 5px !important
    }

    .r29-o {
        border-style: solid !important;
        margin: 0 auto 0 auto !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
        width: 100% !important
    }

    .r30-i {
        color: #3b3f44 !important;
        padding-bottom: 30px !important;
        padding-top: 0px !important;
        text-align: center !important
    }

    body {
        -webkit-text-size-adjust: none
    }

    .nl2go-responsive-hide {
        display: none
    }

    .nl2go-body-table {
        min-width: unset !important
    }

    .mobshow {
        height: auto !important;
        overflow: visible !important;
        max-height: unset !important;
        visibility: visible !important;
        border: none !important
    }

    .resp-table {
        display: inline-table !important
    }

    .magic-resp {
        display: table-cell !important
    }
}
</style>
<!--[if !mso]>
<!-->
<style type="text/css" emogrify="no" scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat");
</style>
<!--
<![endif]-->
<style type="text/css" scoped>
p,
h1,
h2,
h3,
h4,
ol,
ul {
    margin: 0;
}

a,
a:link {
    color: #0092ff;
    text-decoration: underline
}

.nl2go-default-textstyle {
    color: #3b3f44;
    font-family: arial, helvetica, sans-serif;
    font-size: 16px;
    line-height: 1.5
}

.default-button {
    border-radius: 4px;
    color: #ffffff;
    font-family: arial, helvetica, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.15;
    text-decoration: none;
    width: 50%
}

.default-heading1 {
    color: #1F2D3D;
    font-family: arial, helvetica, sans-serif;
    font-size: 36px
}

.default-heading2 {
    color: #1F2D3D;
    font-family: arial, helvetica, sans-serif;
    font-size: 32px
}

.default-heading3 {
    color: #1F2D3D;
    font-family: arial, helvetica, sans-serif;
    font-size: 24px
}

.default-heading4 {
    color: #1F2D3D;
    font-family: arial, helvetica, sans-serif;
    font-size: 18px
}

a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: inherit !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
}

.no-show-for-you {
    border: none;
    display: none;
    float: none;
    font-size: 0;
    height: 0;
    line-height: 0;
    max-height: 0;
    overflow: hidden;
    table-layout: fixed;
    visibility: hidden;
    width: 0;
}
</style>